import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../../styles/careers/intern.module.scss";
import { RecruitNewbiewsLayout } from "../../../organisms/careers/RecruitNewbiewsLayout";
import { Link } from "gatsby";
import { InternHeader } from "../../../organisms/menus/InternHeader";
import { EntryBox } from "../../../atoms/EntryBox";
import { LinkMargin2 } from "../../../atoms/LinkMargin2";
import { SEO } from "../../../atoms/seo/SEO";
import seoImage from "../../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import Scroll, { scrollImageColorTypes } from "../../../atoms/newScroll";
import LineIcon from "../../../images/ad/line.png";

export default ({ location: { pathname } }) => (
  <RecruitNewbiewsLayout current="internship">
    <SEO
      pathname={pathname}
      title="新卒採用エントリー | Entry"
      description="ベンチャー企業、【日本1位】の成長率を誇るスプリーブホールディングスが、（※2018年FINANCIAL TIMES調べ）最高の働き方を見つける必勝法を伝授！エントリーはこちらから。"
      image={seoImage}
    />
    <div className={styles.internTitle}>
      <h2>Internship</h2>
      <h3>インターンシップ</h3>
    </div>

    <div className={styles.blog}>
      <div className={styles.blogContainerLast}>
        <div className={styles.blogContainerLeft}>
          <h2>2022/8/30</h2>
        </div>
        <div className={styles.blogContainerRight}>
          <h3>準備中</h3>
        </div>
      </div>
    </div>

    {/* <Link id="About" />
    <LinkMargin2 />
    <div className={styles.aboutIntern}>
      <div className={styles.InternSlide}>
        <div className={styles.slideWrap}>
          <iframe
            className={styles.slide}
            src="//www.slideshare.net/slideshow/embed_code/key/9bXrr3rIZxrR0t"
          />
        </div>
      </div>
    </div>

    <Link id="Intern" />
    <LinkMargin2 />
    <div className={styles.Internship}>
      <h2>
        Suprieve Internship<span>スプリーブ インターンシップ</span>
      </h2>
      <p>
        社員が実践する【自分”らしさ”を活かす最高の働き方】をテーマに、【成長率1位】を獲得した弊社を存分に体感してください！
      </p>
      <h3>
        １．これから人生を歩んでいく上で大切な【理念】について、
        <br />
        実際に社員研修と同じカリキュラムを利用しグループワークを行います!!
      </h3>
      <h3>
        ２．仕事体験担当より心を込めて【圧倒的】フィードバックを行います!!
        <br />
        成長率の秘訣である≪社風≫を体感していただきます！
      </h3>
      <h4>詳細は上記スライドをご覧ください</h4>
    </div>

    <div className={styles.InternshipGray}>
      <h2>こんな方々が参加しています</h2>
      <div className={styles.whitebox}>
        <p>社会人の方と話してみたい！</p>
        <p>会社に行ってみたい！</p>
        <p>お洒落なオフィスを見てみたい！</p>
        <p>自己分析をしてみたい！</p>
        <p>社会人として大切な考え方を身に着けたい！</p>
        <p>チーム思考力を身に着けたい！</p>
        <p>理想の社会人像に近づきたい！</p>
        <p>インターンシップに参加してみたい！</p>
        <br />
        <h5>１つでも当てはまれば、是非、仕事体験にご参加ください！</h5>
      </div>
      <h2>インターンの様子</h2>
      <div className={styles.stateContainer}>
        <StaticImage
          className={styles.stateImg}
          src="../../../images/career_intern/intern_210114_47.jpg"
          alt=""
        />
        <StaticImage
          className={styles.stateImg}
          src="../../../images/career_intern/intern_210114_59.jpg"
          alt=""
        />
        <StaticImage
          className={styles.stateImg}
          src="../../../images/career_intern/yp0_2199.jpg"
          alt=""
        />
      </div>
    </div>

    <div className={styles.overview}>
      <h2>応募概要</h2>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>応募資格</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>全学年 / 全学部 / 全学科</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>開催場所</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>上記スライドをご確認ください</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>開催日時</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>上記スライドをご確認ください</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>インターンシップの選考</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>なし</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>諸条件</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>
            交通費　なし
            <br />
            宿泊費　なし
            <br />
            報酬　なし
          </p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>応募方法</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>ページ下部LINE@のQRコードより登録のうえエントリーしてください。</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>備考</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>不合格の場合も、選考に影響はありません。</p>
        </div>
      </div>
    </div>

    <div className={styles.arrow}>
      <div className={styles.triangle}></div>
    </div>

    <Link id="Entry" />
    <LinkMargin2 />
    <div className={styles.Entry}>
      <div className={styles.Entry__heading}>
        <img className={styles.Entry__imgSmall} src={LineIcon} alt="" />
        <p className={styles.Entry__headingText}>エントリーはこちら</p>
      </div>

      <h4 className={styles.Entry__messagePc}>
        LINEにてインターンシップへのご応募を受け付けております。
        <br />
        QRコードよりご登録ください。
      </h4>
      <h4 className={styles.Entry__messageSp}>
        LINEにて新卒採用へのご応募を
        <br />
        受け付けております。
      </h4>
      <EntryBox />
    </div> */}
  </RecruitNewbiewsLayout>
);
