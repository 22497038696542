// extracted by mini-css-extract-plugin
export var cover = "n_dS";
export var internTitle = "n_fm";
export var midTopTitle = "n_fn";
export var blog = "n_7";
export var blogContainer = "n_fp";
export var blogContainerLast = "n_fq";
export var blogContainerLeft = "n_fr";
export var blogContainerRight = "n_fs";
export var aboutIntern = "n_ft";
export var InternSlide = "n_fv";
export var aboutgraduate = "n_fw";
export var first = "n_fx";
export var firstText = "n_fy";
export var slide = "n_fz";
export var graduateSlide = "n_fB";
export var InternImg = "n_fC";
export var Internship = "n_fD";
export var InternshipGray = "n_fF";
export var whitebox = "n_fG";
export var stateContainer = "n_fH";
export var stateImg = "n_fJ";
export var overview = "n_fK";
export var overviewContainer = "n_fL";
export var overviewLeft = "n_fM";
export var overviewRight = "n_fN";
export var arrow = "n_fP";
export var triangle = "n_fQ";
export var EntryTitle = "n_fR";
export var Entry = "n_fS";
export var Entry__heading = "n_fT";
export var Entry__headingText = "n_fV";
export var Entry__box = "n_fW";
export var Entry__img = "n_fX";
export var Entry__containerSmall = "n_fY";
export var Entry__imgSmall = "n_fZ";
export var Entry__lineText = "n_f0";
export var Entry__br = "n_f1";
export var Entry__lineTextPc = "n_f2";
export var Entry__qrExplain = "n_f3";
export var Entry__containerRight = "n_f4";
export var Entry__entryLinkSp = "n_f5";
export var Entry__messagePc = "n_f6";
export var Entry__messageSp = "n_f7";
export var midInfo = "n_f8";
export var midInfoContainerOne = "n_f9";
export var midInfoContainerTwo = "n_gb";
export var midInfobutton = "n_gc";
export var tab = "n_gd";
export var contact = "n_z";
export var formContainer = "n_gf";
export var entryBtn = "n_gg";
export var btnTransform = "n_b4";
export var news = "n_gh";
export var nowNews = "n_gj";
export var topTitle = "n_gk";
export var container = "n_gl";
export var pconly = "n_gm";
export var sponly = "n_gn";
export var smallContainer = "n_gp";
export var icon = "n_t";
export var category = "n_gq";
export var newsArchive = "n_gr";
export var reason = "n_gs";
export var reasonTitle = "n_gt";
export var reasonContainer = "n_gv";
export var reasonImg = "n_gw";
export var reasonContent = "n_gx";
export var viewAll = "n_gy";
export var about = "n_gz";
export var aboutTitle = "n_gB";
export var messageContainer = "n_gC";
export var massageContainerLeft = "n_gD";
export var massageContainerRight = "n_gF";
export var messageImg = "n_gG";
export var viewAllGray = "n_gH";
export var view = "n_gJ";